import React, { useState } from 'react';
import { css } from '@emotion/react';
import IconBack from '../svg/back.svg';
import IconCart from '../svg/cart.svg';
import IconCloseMini from '../svg/close-mini.svg';
import IconClose from '../svg/close.svg';
import IconDetail from '../svg/detail.svg';
import IconDotMini from '../svg/dot-mini.svg';
import IconDot from '../svg/dot.svg';
import IconDown from '../svg/down.svg';
import IconDownload from '../svg/download.svg';
import IconFilter from '../svg/filter.svg';
import IconFullscreen from '../svg/fullscreen.svg';
import IconInfo from '../svg/info.svg';
import IconInstagram from '../svg/instagram.svg';
import IconLinkOut from '../svg/link-out.svg';
import IconMaximize from '../svg/maximize.svg';
import IconMinusMini from '../svg/minus-mini.svg';
import IconMinus from '../svg/minus.svg';
import IconPause from '../svg/pause.svg';
import IconPlay from '../svg/play.svg';
import IconPlusMini from '../svg/plus-mini.svg';
import IconPlus from '../svg/plus.svg';
import IconQuestion from '../svg/question.svg';
import IconQuicklook from '../svg/quicklook.svg';
import IconRight from '../svg/right.svg';
import IconSave from '../svg/save.svg';
import IconSearch from '../svg/search.svg';
import IconShare from '../svg/share.svg';
import IconSliderDot from '../svg/slider-dot.svg';
import IconTop from '../svg/top.svg';
import IconMail from '../svg/mail.svg';
import IconMenu from '../svg/menu.svg';
import IconTwitter from '../svg/twitter.svg';
import IconFacebook from '../svg/facebook.svg';
import IconLinkedIn from '../svg/linkedin.svg';
import ConditionalWrapper from './conditional-wrapper';
import Link from './link';

function Icon({
  className,
  svg,
  naturalSize,
  invert,
  active,
  hoverBackground,
  transparent,
  url,
  download,
  onClick,
  onMouseOut,
  onMouseEnter,
}) {
  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';

  return (
    <ConditionalWrapper
      condition={url}
      wrapper={(children) => (
        <Link className={className} to={url} download={download}>
          {children}
        </Link>
      )}
    >
      <div
        className={url ? '' : className}
        css={css`
          cursor: pointer;

          &:hover {
            svg {
              circle {
                fill: ${active ? white : hoverBackground ? hoverBackground : black};
              }

              path {
                fill: ${active ? black : white};
              }
            }
          }

          svg {
            display: block;
            pointer-events: none;
            width: ${naturalSize ? 'inherit' : '4.4rem'};
            height: ${naturalSize ? 'inherit' : '4.4rem'};

            @media (max-width: 800px) {
              width: ${naturalSize ? 'inherit' : '4rem'};
              height: ${naturalSize ? 'inherit' : '4rem'};
            }

            circle {
              stroke: ${black};
              fill: ${transparent ? 'transparent' : active ? black : white};
            }

            path {
              fill: ${active ? white : black};
            }
          }
        `}
        onClick={onClick}
        onMouseOut={onMouseOut}
        onMouseEnter={onMouseEnter}
      >
        {svg}
      </div>
    </ConditionalWrapper>
  );
}

export function Back({ className, invert, transparent, url, onClick }) {
  return (
    <div className={className}>
      <Icon
        svg={<IconBack />}
        invert={invert}
        transparent={transparent}
        url={url}
        onClick={onClick}
      />
    </div>
  );
}

export function Cart({
  className,
  count,
  active,
  invert,
  hoverBackground,
  onClick,
  onMouseOut,
  onMouseEnter,
  url,
}) {

  const white = (active && !invert) || (invert && !active) ? 'var(--black)' : 'var(--white)';
  const black = (active && !invert) || (invert && !active) ? 'var(--white)' : 'var(--black)';
  return (
    <div
      className={className}
      css={css`
        position: relative;
        display: inline-block;
        line-height: 0;

        &:hover {
          span {
            color: ${(hoverBackground) ? 'black' : white};
          }

          svg circle {
            fill: ${(hoverBackground) ? hoverBackground : black};
          }
        }
      `}
    >
      <span
        className="type--mono-heading"
        css={css`
          position: absolute;
          top: 55%;
          left: 52%;
          font-size: 1.8rem !important;
          color: ${black};
          transform: translate(-50%, -50%);
          pointer-events: none;
        `}
      >
        {typeof count === 'number' ? String(count) : '0'}
      </span>
      <Icon
        svg={<IconCart />}
        hoverBackground={hoverBackground}
        active={active}
        invert={invert}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        url={url}
      />
    </div>
  );
}

export function Close({ className, mini, invert, active, transparent, onClick }) {
  const white = invert ? 'var(--black)' : 'var(--white)';
  const black = invert ? 'var(--white)' : 'var(--black)';

  return (
    <Icon
      className={className}
      svg={mini ? <IconCloseMini /> : <IconClose />}
      naturalSize={mini}
      active={active}
      transparent={transparent}
      invert={invert}
      onClick={onClick}
      css={
        mini &&
        css`
          &:hover {
            path {
              stroke: ${active ? black : white};
            }
          }

          path {
            stroke: ${active ? white : black};
          }
        `
      }
    />
  );
}

export function Detail({ className, invert, active, transparent, onClick }) {
  return (
    <Icon
      className={className}
      invert={invert}
      active={active}
      transparent={transparent}
      svg={<IconDetail />}
      onClick={onClick}
      css={css`
        path:nth-child(1), path:nth-child(2) {
          fill: var(--white);
        }
      `}
    />
  );
}

export function Dot({ className, active, mini, invert, transparent, onClick }) {
  return (
    <Icon
      className={className}
      svg={mini ? <IconDotMini /> : <IconDot />}
      naturalSize={true}
      invert={invert}
      active={active}
      onClick={onClick}
      css={
        !mini &&
        css`
          @media (max-width: 1024px) {
            svg {
              width: 2rem;
              height: 2rem;
            }
          }

          @media (max-width: 550px) {
            svg {
              width: 1.6rem;
              height: 1.6rem;
            }
          }
        `
      }
    />
  );
}

export function Down({ className, onClick, invert, transparent }) {
  return <Icon className={className} onClick={onClick} svg={<IconDown />} />;
}

export function Download({ className, url, download, invert, transparent }) {
  return (
    <Icon
      className={className}
      url={url}
      download={download}
      svg={<IconDownload />}
      download={download}
    />
  );
}

export function FilterButton({ className, invert, transparent, onClick }) {
  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';

  return (
    <Icon
      className={className}
      svg={<IconFilter />}
      css={css`
        &:hover {
          line {
            stroke: ${white};
          }

          circle:nth-of-type(2),
          circle:nth-of-type(3) {
            stroke: ${white};
            fill: ${black};
          }
        }
      `}
      onClick={onClick}
    />
  );
}

export function Fullscreen({ invert, transparent }) {
  return <Icon invert={invert} transparent={transparent} svg={<IconFullscreen />} />;
}

export function Info({ className, invert, active, transparent, onClick }) {
  return (
    <Icon
      className={className}
      invert={invert}
      active={active}
      transparent={transparent}
      svg={<IconInfo />}
      onClick={onClick}
    />
  );
}

export function LinkOut({ className, invert, transparent }) {
  return (
    <Icon className={className} invert={invert} transparent={transparent} svg={<IconLinkOut />} />
  );
}

export function Maximize({ className, invert, transparent, onClick }) {
  return (
    <Icon
      className={className}
      invert={invert}
      transparent={transparent}
      svg={<IconMaximize />}
      onClick={onClick}
    />
  );
}

export function Menu({ className, invert, transparent, onClick }) {
  return (
    <Icon
      className={className}
      invert={invert}
      transparent={transparent}
      svg={<IconMenu />}
      onClick={onClick}
    />
  );
}

export function Minus({ className, mini, active, invert, transparent, onClick }) {
  return (
    <Icon
      className={className}
      svg={mini ? <IconMinusMini /> : <IconMinus />}
      active={active}
      naturalSize={mini}
      invert={invert}
      transparent={transparent}
      onClick={onClick}
    />
  );
}

export function Pause({ invert, transparent }) {
  return (
    <Icon
      svg={<IconPause />}
      naturalSize={true}
      invert={invert}
      transparent={transparent}
      css={css`
        svg {
          @media (max-width: 1024px) {
            width: 5.2rem;
            height: 5.2rem;
          }

          @media (max-width: 550px) {
            width: 4.4rem;
            height: 4.4rem;
          }
        }
      `}
    />
  );
}

export function Play({ className, invert, transparent, onClick }) {
  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';

  return (
    <Icon
      className={className}
      naturalSize={true}
      invert={invert}
      transparent={transparent}
      svg={<IconPlay />}
      onClick={onClick}
      css={css`
        path {
          fill: ${transparent ? 'transparent' : white} !important;
        }

        svg {
          &:hover {
            path {
              stroke: ${white};
              fill: ${black} !important;
            }
          }

          @media (max-width: 550px) {
            width: 8rem;
            height: 8rem;
          }
        }
      `}
    />
  );
}

export function Plus({ className, mini, invert, transparent, onClick }) {
  return (
    <Icon
      className={className}
      svg={mini ? <IconPlusMini /> : <IconPlus />}
      naturalSize={mini}
      invert={invert}
      transparent={transparent}
      onClick={onClick}
    />
  );
}

export function Question({ invert, transparent }) {
  return <Icon invert={invert} transparent={transparent} svg={<IconQuestion />} />;
}

export function Quicklook({ invert, active, transparent, onClick }) {
  return (
    <Icon
      active={active}
      invert={invert}
      transparent={transparent}
      svg={<IconQuicklook />}
      onClick={onClick}
    />
  );
}

export function Right({ className, invert, transparent, url, onClick }) {
  return (
    <Icon
      className={className}
      invert={invert}
      transparent={transparent}
      svg={<IconRight />}
      url={url}
      onClick={onClick}
    />
  );
}

export function Save({ className, active, invert, transparent, onClick }) {
  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';

  return (
    <Icon
      className={className}
      invert={invert}
      transparent={transparent}
      svg={<IconSave />}
      onClick={onClick}
      css={css`
        path:nth-of-type(1) {
          fill: ${active ? black : white};
        }

        &:hover {
          path:nth-of-type(1) {
            fill: ${active ? white : black};
          }

          path:nth-of-type(2) {
            fill: ${active ? 'transparent' : white};
          }
        }
      `}
    />
  );
}

export function Search({ className, invert, transparent, onClick }) {
  return (
    <Icon
      className={className}
      invert={invert}
      transparent={transparent}
      svg={<IconSearch />}
      onClick={onClick}
    />
  );
}

export function Share({ className, invert, transparent, onClick }) {
  return (
    <Icon
      className={className}
      invert={invert}
      transparent={transparent}
      svg={<IconShare />}
      onClick={onClick}
    />
  );
}

export function SliderDot({ className, invert }) {
  return (
    <div
      className={className}
      invert={invert}
      css={css`
        svg circle {
          stroke: transparent;
          fill: ${invert ? 'var(--white)' : 'var(--black)'};
        }
      `}
    >
      <IconSliderDot
        css={css`
          display: block;

          circle {
            stroke: transparent;
            fill: var(--black);
          }
        `}
      />
    </div>
  );
}

export function Top({ invert, transparent }) {
  return <Icon invert={invert} transparent={transparent} svg={<IconTop />} />;
}

export function Mail({ invert, transparent, url }) {
  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';
  return (
    <Icon
      invert={invert}
      transparent={transparent}
      svg={<IconMail />}
      url={url}
      css={css`
        &:hover {
          svg path {
            stroke: ${white};
            fill: ${black} !important;
          }
        }

        svg path {
          stroke: ${black};
          fill: ${transparent ? 'transparent' : white} !important;
        }
      `}
    />
  );
}

export function Twitter({ invert, transparent, url }) {
  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';

  return (
    <Icon
      invert={invert}
      transparent={transparent}
      svg={<IconTwitter />}
      url={url}
      css={css`
        svg path {
          stroke: ${black};
        }

        &:hover {
          svg path {
            stroke: ${white};
          }
        }
      `}
    />
  );
}

export function Facebook({ invert, transparent, url }) {
  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';

  return (
    <Icon
      invert={invert}
      transparent={transparent}
      svg={<IconFacebook />}
      url={url}
      css={css`
        &:hover svg path {
          stroke: ${black};
        }

        svg path {
          stroke: ${transparent ? 'transparent' : white};
        }
      `}
    />
  );
}

export function Instagram({ invert, url }) {
  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';

  return (
    <Icon
      invert={invert}
      svg={<IconInstagram />}
      url={url}
      css={css`
        svg path {
          stroke: transparent;
        }
      `}
    />
  );
}

export function LinkedIn({ invert, transparent, url }) {
  const black = invert ? 'var(--white)' : 'var(--black)';
  const white = invert ? 'var(--black)' : 'var(--white)';

  return (
    <Icon
      invert={invert}
      transparent={transparent}
      svg={<IconLinkedIn />}
      url={url}
      css={css`
        &:hover {
          svg path {
            stroke: ${black};
          }
        }

        svg path {
          stroke: ${white};
        }
      `}
    />
  );
}
